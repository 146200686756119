:root {
  --theme-background-primary: #080712;
  --theme-background-secondary: #0d0f11;
  --theme-background-third: #191d23;
  --theme-btn: #f81c61;
  --theme-title-font: #fff;
  --theme-title-background: #202242;
  --theme-conversation-font: #000;
  --theme-font-primary: #fff;
  --theme-font-secondary: #646572;
}

$theme-background-primary: var(--theme-background-primary);
$theme-background-secondary: var(--theme-background-secondary);
$theme-background-third: var(--theme-background-third);
$theme-btn: var(--theme-btn);
$theme-title-font: var(--theme-title-font);
$theme-title-background: var(--theme-title-background);
$theme-conversation-font: var(--theme-conversation-font);
$theme-font-primary: var(--theme-font-primary);
$theme-font-secondary: var(--theme-font-secondary);

// $theme-color: #ff6a2f;
// $text-color-primary: #3c3c3c;
// $theme-border: #e1e1e1;
// $text-color-secondary: #ffff;
// $card-background: #fcfcfc;
// $card-border: #e1e1e1;
