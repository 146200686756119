@use "../../styles/mainsource";
@use "../../MainStyle";

.button-primary1 {
  background: linear-gradient(97.26deg, #f33a56 -13.78%, #6e039f 426.6%);
  border-radius: 5px;
  border: 0;
  font-weight: 600;
  // margin-top: -6px;
  font-size: 15px;
  width: 8.2rem;
  color: #fff;
}

// .navbar-brand {
//   /* padding-top: var(--bs-navbar-brand-padding-y); */
//   /* padding-bottom: var(--bs-navbar-brand-padding-y); */
//   // margin-left: 110px;
//   /* margin-right: var(--bs-navbar-brand-margin-end); */
//   font-size: var(--bs-navbar-brand-font-size);
//   color: var(--bs-navbar-brand-color);
//   text-decoration: none;
//   white-space: nowrap;
//   // border-right: 4px solid #fff !important;

// }
.vl {
  border-left: 2px solid #fff;
  height: 24px;
  padding: 5px;
  margin-left: 1rem;
  margin-top: 0.5rem;
}

.mobile-launch.nav-question-search .ql-container.ql-snow {
  width: 196px;
  padding: 0px;
}

.desktopnav {
  position: fixed;
  width: 83.4%;
  border-radius: 0rem 0rem 1rem 1rem;
}

nav.navbar {
  background: MainStyle.$theme-background-third;
  // background-image: linear-gradient(150deg, #2c0e34 5%, MainStyle.$theme-background-primary 44%);
  // background: transparent;
  // padding: 1rem 5rem;
  padding: 1rem 3rem;
  z-index: 2;
}

.sdale-nav {
  padding: 1rem rem 1rem 1rem !important;
}

.sdelalogo {
  width: 12%;
  margin-right: 10px;
}

nav.nav-height {
  height: 4.3rem;
}

.navlogo {
  height: 1.5rem;
}

a.nav-link {
  display: flex;
  align-items: center;
  margin: 0rem 0.3rem;
  font-size: 0.875rem;
  font-weight: mainsource.$font-weight700;
  color: mainsource.$text-color-grey !important;
  cursor: pointer;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  width: 16rem !important;
  position: absolute;
  top: 3rem;
  left: -13rem;
}

a.nav-link:active {
  color: red !important;
}

a.signup {
  font-weight: mainsource.$font-weight700;
  margin-left: 2rem;
}

.colorSecondary {
  color: #232323 !important;
}

// position: absolute;
// left: -60%;
// top: 3rem;
// width: fit-content;

.profile- {
  height: 4vh;
  width: 2vw;
}

.icon {
  height: inherit;
  width: inherit;
  border-radius: 5px;
}

// .desktopnav {
//   display: block;
// }

// .mobilenav {
//   display: none;
// }

.faRegBell {
  color: #1a3387 !important;
}

// .navbar-expand-lg .navbar-collapse {
//   margin-top: -40px;
// }

.navBarItem {
  color: #1c3892 !important;
}

// a.navBarItem:active {

// }
.navBarItemActive {
  background: linear-gradient(90.95deg, #f33a56 5.93%, #6e039f 131.13%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}

.dropdown-item:active span,
.dropdown-item:active,
.dropdown-item:active a {
  // background-color: #1a3387 !important;
  // color: white !important;
}

.button-primary.signin {
  padding: 0.5rem 2rem;
}

a .navBarItem:hover {
  background: linear-gradient(
    90.95deg,
    #f33a56 5.93%,
    #6e039f 131.13%
  ) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  transition: all 0.2s;
  scale: 1.2;
}

.nav-notification-bell {
  color: #fff;
  font-size: 27px;
}

.nav-dropdown {
  position: relative;

  font-size: 2.5rem;
  margin: -27px;
}

.nav-question-search {
  color: rgb(255 255 255 / 17%);
}

// .nav-question-search .ql-container.ql-snow {
//   border-radius: 12px;
//   border: 1px solid #424352;
//   background: MainStyle.$theme-background-secondary;

// }
.nav-question-search .ql-container.ql-snow {
  border-radius: 6px;
  // border: 1px solid #424352;
  background: transparent;
  // width: 300px;
  // background-color: #f33a56;
}

.user-icon {
  font-size: 20px;
}

.nav-drop-down-icon {
  color: #fff;
}

.dropdown-toggle::after {
  display: none;
}

.nav-question-search.expanded .ql-container.ql-snow {
  width: 600px;
  margin-left: -374px;
}

.nav-question-search .ql-editor {
  color: #c2c2c2;
  /* Change this to your desired text color */
  height: 43px;
}

.nav-question-search .ql-editor::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.nav-question-search .ql-editor::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 3px #D9E9FF;
  background-color: #D9E9FF; */
  background-color: MainStyle.$theme-background-secondary;
  border-radius: 0.5rem;
}

.dropdown-menu {
  background-color: MainStyle.$theme-background-third;
  --bs-dropdown-link-hover-bg: none;
  --bs-dropdown-link-active-bg: none;
}

.dropdown-item {
  color: white !important;
  font-size: 0.8rem;
}

.nav-question-search .ql-editor::-webkit-scrollbar-thumb {
  background-color: #9a9a9a;
  border-radius: 10px;
  border: 2px solid MainStyle.$theme-background-secondary;
}

/* Placeholder color */
.nav-question-search .ql-editor::before {
  color: rgba(255, 255, 255, 0.17);
}

.test {
  display: none;
}

.share-icon {
  width: 1.2rem;
  height: 1.2rem;
  // font-size: 16px;
}

.test {
  color: #fff !important;
}

.navbar-user-dropdown {
  display: none;
}

.menu-icon {
  display: none;
}

.mobile-logout-dropdown-font {
  font-size: 14px;
}

.navcompanylogo {
  padding-top: 0.5rem;
  width: 6rem;
  height: 2rem;
}

.log-out-dropdown .ant-dropdown {
  box-shadow: 1rem 1rem 1rem 1rem #fff;
}

.ant-dropdown {
  box-shadow: 0px 0px 0.8rem MainStyle.$theme-background-secondary;
  border-radius: 4px;
}
.navbar-expand-lg .navbar-collapse {
  display: flex !important;
  // flex-basis: auto;
  justify-content: flex-end;
}
@media only screen and (max-width: 768px) {
  .menu-icon {
    // display: block;
    font-size: 18px;
    color: white;
  }

  .navbar-user-dropdown {
    display: block;
    float: right !important;
  }

  .menu-icon-user {
    font-size: 18px;
    color: white;
  }

  nav.navbar {
    // background-image: linear-gradient(
    //   150deg,
    //   #2c0e34 5%,
    //   MainStyle.$theme-background-primary 44%
    // );
    // background: transparent;
    padding: 1rem 1rem;
    z-index: 2;
    // border-bottom: 1px solid #5e4671;
  }

  .navbar-brand {
    /* padding-top: var(--bs-navbar-brand-padding-y); */
    /* padding-bottom: var(--bs-navbar-brand-padding-y); */
    // margin-left: 110px;
    /* margin-right: var(--bs-navbar-brand-margin-end); */
    font-size: var(--bs-navbar-brand-font-size);
    color: var(--bs-navbar-brand-color);
    text-decoration: none;
    white-space: nowrap;
  }
  .drop-menu-icon {
    top: 1rem;
    color: var(--theme-btn);
    font-size: var(--bs-navbar-brand-font-size);
  }
  .dropmenu-mobile {
    display: none;
  }

  .dropdown-menu.show {
    display: block !important;
    // left: -200px;
    // right:13px !important;
    // width: 257px !important;
  }

  .nav-question-search.expanded .ql-container.ql-snow {
    width: 248px;
    margin-left: -14px;
  }

  .nav-question-search .ql-container.ql-snow {
    width: 86%;
    font-size: 13px;
    margin-left: 0px;
    margin-top: 0px;
  }
}

@media only screen and (max-width: 2304px) and (min-width: 2161px) {
}

@media only screen and (max-width: 2160px) and (min-width: 1922px) {
}

@media only screen and (max-width: 1921px) and (min-width: 1681px) {
}

@media only screen and (max-width: 1680px) and (min-width: 1601px) {
}

@media only screen and (max-width: 1600px) and (min-width: 1471px) {
}

@media only screen and (max-width: 1470px) and (min-width: 1441px) {
}

@media only screen and (max-width: 1440px) and (min-width: 1367px) {
}

@media only screen and (max-width: 1366px) and (min-width: 1281px) {
}

@media only screen and (max-width: 1280px) and (min-width: 1081px) {
  .dropmenu-mobile {
    display: none !important;
  }
}

@media only screen and (max-width: 1080px) and (min-width: 1025px) {
  .dropmenu-mobile {
    display: none !important;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 769px) {
  nav.nav-height {
    height: 4rem;
  }
  .dropmenu-mobile {
    display: none !important;
  }
}

@media only screen and (max-width: 768px) and (min-width: 427px) {
  .navbar-user-dropdown {
    // margin-left: 23rem;
    margin-left: 29rem;
  }

  .nav-question-search .ql-container.ql-snow {
    width: 100%;
    font-size: 13px;
    margin-left: 0px;
    margin-top: 0px;
  }
  nav.nav-height {
    height: 4rem;
  }
  .dropmenu-mobile {
    display: none !important;
  }
}

@media only screen and (max-width: 426px) and (min-width: 377px) {
  .dropmenu-mobile {
    display: block !important;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    // flex-basis: auto;
    justify-content: space-between;
  }
  .navbar-user-dropdown {
    margin-left: 80px;
  }

  .navbar-brand {
    margin-left: 60px;
  }

  // .nav-question-search .ql-container.ql-snow {
  //   width: 196px;
  //   padding: 0px;
  // }
  .nav-question-search .ql-container.ql-snow {
    width: 100%;
    font-size: 13px;
    margin-left: 0px;
    margin-top: 0px;
  }
  nav.nav-height {
    height: 3.8rem;
  }
}

@media only screen and (max-width: 376px) and (min-width: 321px) {
  .navbar-user-dropdown {
    margin-left: 72px;
  }

  .nav-question-search .ql-container.ql-snow {
    width: 100%;
    font-size: 13px;
    margin-left: 0px;
    margin-top: 0px;
  }
  .dropmenu-mobile {
    display: block !important;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    justify-content: space-between;
  }
}

@media (max-width: 320px) {
  .dropmenu-mobile {
    display: block !important;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    justify-content: space-between;
  }
  .navbar-brand {
    /* padding-top: var(--bs-navbar-brand-padding-y); */
    /* padding-bottom: var(--bs-navbar-brand-padding-y); */
    // margin-left: 65px;
    /* margin-right: var(--bs-navbar-brand-margin-end); */
    font-size: var(--bs-navbar-brand-font-size);
    color: var(--bs-navbar-brand-color);
    text-decoration: none;
    white-space: nowrap;
  }

  .navbar-user-dropdown {
    margin-left: 0px;
  }

  .navbar-brand {
    padding-left: -4%;
  }

  .nav-question-search .ql-container.ql-snow {
    width: 100%;
    font-size: 13px;
    margin-left: 0px;
    margin-top: 0px;
  }
}
