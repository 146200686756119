@use "../../MainStyle";

.MyProfile {
  display: grid;
  grid-template-columns: 0.7fr 3fr;
  height: 82vh;
}

.MyProfile {
  display: grid;
  grid-template-columns: 0.7fr 3fr;
  height: 82vh;
}

.DashboardNav {
  padding: 1rem;
  border-right: 1px solid lightgray;
  height: 100% !important;
  width: revert;
}

.DashboardNavList {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 1rem 1rem;
  margin-bottom: 2rem;
  border-radius: 10px;
  border-radius: 10px;
  transition: all 0.6s;
}

.DashboardNavList p {
  margin: 0;
  margin-left: 10px;
  color: lightgray;
  font-size: 14px;
}

.DashboardNavList:hover {
  cursor: pointer;
}

.DashboardNavList_active {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 1rem 0.3rem;
  box-shadow: 0px 69px 116px rgba(28, 56, 146, 0.14) !important;
  margin-bottom: 2rem;
  transform: scale(1.05);
  border-radius: 10px 0 0 10px;
  border-right: 2px solid #213a9c;
  transition: all 0.6s;
}

.DashboardNavList_active p {
  margin: 0;
  margin-left: 10px;
  color: #213a9c;
  font-weight: 500;
  font-size: 14px;
}

.DashboardNavList_active:hover {
  cursor: pointer;
}

.DashboardNavicon {
  color: black;
}

.superlancer-button {
  background: linear-gradient(93.12deg, #f33a56 -1.67%, #6e039f 354.14%);
  border-radius: 23.5px 0px;
  border: none;
  padding: 0.8rem 1.2rem;
  font-weight: 500;
}

.superlancer-button:hover {
  background: white;
  color: #f33a56;
  border: 1px solid #f33a56;
  transition: all 0.3s;
  box-shadow: 0px 30px 91px rgba(28, 56, 146, 0.14);
}

/*# sourceMappingURL=Myprofile.css.map */

.DashboardNav {
  padding: 1rem;
  border-right: 1px solid lightgray;
  height: 100% !important;
  width: revert;
}

.DashboardNavList {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border-radius: 10px;
  border-radius: 10px;
  transition: all 0.6s;

  & p {
    margin: 0;
    margin-left: 10px;
    color: lightgray;
    font-size: 14px;
  }

  &:hover {
    cursor: pointer;
  }
}

.DashboardNavList_active {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 1rem 0.3rem;
  box-shadow: 0px 69px 116px rgba(28, 56, 146, 0.14) !important;
  margin-bottom: 1rem;
  transform: scale(1.05);
  border-radius: 10px 0 0 10px;
  border-right: 2px solid #213a9c;
  transition: all 0.6s;

  & p {
    margin: 0;
    margin-left: 10px;
    color: #213a9c;
    font-weight: 500;
    font-size: 14px;
  }

  &:hover {
    cursor: pointer;
  }
}

.DashboardNavicon {
  color: #737791;
}

.howToText {
  background: linear-gradient(91.69deg, #f33a56 9.13%, #6e039f 160.43%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-size: 13px;
  text-align: center;
  padding-bottom: 1rem;
  font-weight: 500;
}

.manageJobsIcon {
  width: 1.5rem !important;
}

.superlancer-button {
  background: linear-gradient(93.12deg, #f33a56 -1.67%, #6e039f 354.14%);
  border-radius: 23.5px 0px;
  border: none;
  padding: 0.8rem 1.2rem;
  font-weight: 500;

  &:hover {
    background: white;
    color: #f33a56;
    border: 1px solid #f33a56;
    transition: all 0.3s;
    box-shadow: 0px 30px 91px rgba(28, 56, 146, 0.14);
  }
}

.pink-icon {
  color: MainStyle.$theme-btn !important;
}

.active-menu-item {
  border-left: 5px solid MainStyle.$theme-btn;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.float-end_right {
  float: right !important;
}

.newflex {
  display: flex;
}

.weblayout {
  display: block;
}

.cartdiv {
  height: 100%;
  width: 100%;
}

// .navbarNew1 {
//   display: none;
// }
// .mobilelayout {
//   display: none;
// }
.navbarNew1 {
  visibility: hidden;
}

.navbarNew1 {
  overflow: hidden;
  background-color: white;
  position: fixed;
  color: black;
  bottom: 0;
  width: 100%;
  padding: 0px 0px;
  z-index: 1000;
  display: flex !important;
  justify-content: center !important;
}

.navbarNew1 a {
  float: left;
  display: block;
  color: lightgray;
  text-align: center;
  padding: 5px 5px;
  text-decoration: none;
  font-size: 17px;
}

.navbarNew1 a:hover {
  color: lightgray;
  font-weight: 600;
}

.navbarNew1 a.active,
.DashboardNavicon {
  /* background-color: #04AA6D; */
  // color: #1c3892;
  // font-weight: 600;
  font-size: 30px;
  // margin-right: 0.8rem;
  margin-top: 0px;
  // color:MainStyle.$theme-btn;
  // color: #fff;
  // margin-left: 2.4rem !important;
}

.nav-icon-sidebar {
  display: flex;
  justify-content: center;
}

// .DashboardNavicon>.active {
//   color:#fff !important
// }
.costimize-sidebar {
  height: 100vh !important;
  position: fixed !important;
  border: none !important;
  background: MainStyle.$theme-background-third;
}

.costimize_header_logo {
  width: 145px;
  height: 29px;
  margin-top: 4px;
}

.costimize-sidebar > .ps-sidebar-container {
  // background-color: var(--costimize_site_color) !important;
  background: transparent;
  padding-top: 16px;
  color: #db2aac;
  // border-right: 1px solid #5e4671;
  font-family: "DM Sans" !important;
}

.label-sidemenu {
  font-size: 15px;
  text-align: center;
  color: #737791;
  // font-weight: 600;
  padding-left: 1rem;
  line-height: 18px;
  letter-spacing: 0em;
  // text-align: left;
}

.lable-sidemenu-icon {
  width: 33px;
}

.mobile-costimize-sidebar > .ps-sidebar-container {
  // background-color: var(--costimize_site_color) !important;
  padding-top: 70px;
  color: MainStyle.$theme-background-primary;
}

.img-right{
    margin-left: -6px;
}

.sidebar-footer {
  position: fixed;
  bottom: -18px;
  width: 100%;
  padding: 15px;
  /* background-color: inherit; */
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.footer-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px; /* Space between image and text */
}
.powered-by-image {
  max-height: 36px;
  width: auto;
}
.cloudhub-text {
  /* margin-top: 8px; */
  font-size: 18px;
  color: white;
  /* width: 6rem; */
}
.cloudhub-text1 {
  font-size: 12px;
  color: white;
}

.costimize-sidebar > .ps-sidebar-container > .ps-menu-root {
  font-size: 20px !important;
}

.ps-submenu-content.ps-open {
  background: transparent;
  color: #fff;
  font-size: 16px;
}

.costimize-sidebar .sidebar-desktop .css-1wvake5 {
}

.css-1wvake5 {
  // width: auto !important;
  // min-width: 0rem !important;
  width: 100% !important;
  max-width: 16rem !important;
}

.css-1tqrhto > .ps-menu-button:hover {
  background-color: MainStyle.$theme-background-primary !important;
}

.costimize-sidebar .sidebar-desktop .css-1t8x7v1 > .ps-menu-button {
  padding-left: 0px;
}

.css-1t8x7v1 > .ps-menu-button {
  padding-left: 0px !important;
}

.css-12w9als:hover {
  background-color: transparent;
}

.active > .ps-menu-button {
  // background-color: MainStyle.$theme-background-primary !important;
  color: MainStyle.$theme-btn !important;
}

// .active>.DashboardNavicon{
//   color:MainStyle.$theme-btn !important;
// }

.ant-tabs-dropdown-menu {
  background: MainStyle.$theme-background-secondary !important;
}

.ant-tabs-dropdown-menu-item {
  color: #fff !important;
}

// li.active {
//   background-color: MainStyle.$theme-background-primary !important;
// }
span.ps-submenu-expand-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.costimize-sidebar
  > .ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .ps-menuitem-root {
  // height: 65px !important;
}

.costimize-sidebar
  > .ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .ps-menuitem-root
  > .ps-menu-button
  > .ps-menu-label {
  display: flex;
  align-items: center;
  padding: 1rem;
}

.costimize-sidebar
  > .ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .ps-menuitem-root
  > .ps-menu-button {
  margin-bottom: 1rem;
  /* border-radius: 10px; */
  transition: all 0.6s;
  /* margin-left: 10px; */
  padding-left: 0.1rem;

  margin-right: 1px;
  background: transparent;
  // color: #c4c4c4;
  font-size: 22px;
  font-weight: 500;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.css-1t8x7v1 > .ps-menu-button {
  padding-right: 0px !important;
  // padding-left: 7px;
}

.css-1t8x7v1 > .ps-menu-button {
  // padding-right: 0px;
}

// .costimize-sidebar
//   > .ps-sidebar-container
//   > .ps-menu-root
//   > .css-ewdv3l
//   > .ps-menuitem-root
//   > .ps-menu-button :hover {

//     cursor: pointer;
//     background-color: white;
//     box-shadow: 0px 69px 116px rgba(28, 56, 146, 0.14) !important;
//     color: #213a9c;
// }
.costimize-sidebar
  > .ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .ps-menuitem-root
  > .ps-menu-button:hover {
  cursor: pointer;
  background-color: transparent;
  box-shadow: 0px 69px 116px rgba(28, 56, 146, 0.14) !important;
  // color: #fff;
  color: MainStyle.$theme-btn;
}

.costimize-sidebar
  > .ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .ps-menuitem-root.active {
  // background-color: var(--costimize_background_color) !important;
  // color: var(--costimize_site_color) !important;
  position: relative;
  color: MainStyle.$theme-btn;
  // box-shadow: 0px 69px 116px rgba(28, 56, 146, 0.14) !important;
}

.costimize-sidebar
  > .ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .active
  > .ps-menu-button {
  // background-color: #591960 !important;
  // color: #fff;
  // box-shadow: 0px 69px 116px rgba(28, 56, 146, 0.14) !important;
  color: MainStyle.$theme-btn !important;
}

// .costimize-sidebar
//   > .ps-sidebar-container
//   > .ps-menu-root
//   > .css-ewdv3l
//   > .ps-menuitem-root:hover {
//   background-color: var(--costimize_background_color) !important;
//   color: var(--costimize_site_color) !important;
//   position: relative;
// }
/* .sidebar-budget-without-white:hover{
    display: none;
}
.sidebar-budget-with-white:hover{
    display: block;
} */
.costimize-sidebar
  > .ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .ps-menuitem-root.active::before {
  content: "";
  position: absolute;
  background-color: transparent;
  bottom: -46px;
  height: 46px;
  // width: 16px;
  left: 48px;
  border-top-right-radius: 25px;
  box-shadow: 0 -25px 0 0 var(--costimize_background_color);
}

.costimize-sidebar
  > .ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .ps-menuitem-root.active::after {
  content: "";
  position: absolute;
  background-color: transparent;
  bottom: 65px;
  height: 46px;
  // width: 16px;
  left: 48px;
  border-bottom-right-radius: 25px;
  box-shadow: 0px 25px 0px 0px var(--costimize_background_color);
}

.footer-token-icon {
  margin-top: -2px;
  font-size: 11px;
}

.sidebar-content {
  font-size: 17px;
  font-weight: 400;
  color: #fff;
}

.token-nav-logo {
  width: 25px;
}

.token-footer-logo {
  width: 15px;
}

.recent-table-agent-name {
  color: #f34fed;
}

.recent-table-credits {
  border-radius: 10px;
  border: 1px solid MainStyle.$theme-btn;
  background: rgba(215, 54, 209, 0.3);
  padding: 3px;
}

.sidebar-mobile {
  display: none;
}
.sidebar-text-mobile {
  display: block;
}
.token-details-subheader {
  color: MainStyle.$theme-btn;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0.5rem 0;
}

.api-details-subheader {
  color: MainStyle.$theme-btn;
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
}

.token-details-question {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  height: 13rem;
  overflow-y: auto;
}
.token-details-question-model {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  overflow-y: auto;
}
.api-details-question {
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0.5rem 0;
  overflow-y: auto;
  align-items: center;
  display: flex;
}

.token-details-answer {
  font-size: 1rem;
  font-weight: 350;
  margin-bottom: 0.5rem;
  height: 13rem;
  overflow-y: auto;
}
.token-details-question::-webkit-scrollbar-track,
.token-details-answer::-webkit-scrollbar-track {
  background-color: transparent;
}
.token-details-content {
  border-top: 1px solid #8a8a8a;
  border-bottom: 1px solid #8a8a8a;
}
.api-details-content {
  border-top: 1px solid #8a8a8a;
  border-bottom: 1px solid #8a8a8a;
  padding: 1rem;
}
.token-details-header {
  color: MainStyle.$theme-btn;
  align-items: center;
  justify-content: start;
  display: flex;
  font-size: 1.9rem;
  font-weight: 600;
  margin-bottom: 1rem;
}
.input-tokens {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #53343e;
  border: 1px solid #f81c61;
  height: fit-content;
  width: 5rem;
  padding: 0.5rem;
  border-radius: 10px;
  margin: 0.5rem 0 1rem 0;
}
.output-tokens {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #564a31;
  border: 1px solid #fdad09;
  height: fit-content;
  width: 5rem;
  padding: 0.5rem;
  border-radius: 10px;
  margin: 0.5rem 0 1rem 0;
}
.sidebar-desktop {
  display: block;
}

.recent-prompts-table {
  width: 100%;
  overflow-x: scroll;
}

.recent-prompts-table::-webkit-scrollbar-thumb {
  background: var(--theme-background-primary) !important;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--theme-background-primary) !important;
  border-radius: 10px;
}

.recent-prompts-table thead th {
  /* background-color: MainStyle.$theme-background-primary !important;
    border-radius: 13.226px 13.226px 0px 0px; */
  background: rgba(255, 255, 255, 0.06) !important;

  /* background-color: #fffe00; */
  color: #fff !important;
  border: none !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 100% !important;
  white-space: nowrap;
  text-align: center !important;
  z-index: 0 !important;
}

.recent-prompts-table .ant-table {
  background: MainStyle.$theme-background-third !important;
  color: #fff;
}

.recent-prompts-table tbody tr td {
  /* border-bottom: 1px solid #999; */
  border: none;
  border-bottom: 1px solid #545454 !important;
}

.recent-prompts-table .ant-table-cell-row-hover {
  background: transparent !important;
}

.recent-prompts-table a:hover {
  color: var(--purple-color);
}

.recent-prompts-table
  .ant-pagination
  .ant-pagination-disabled:hover
  .ant-pagination-item-link {
  color: #fff;
}

.recent-prompts-table .ant-pagination {
  color: #fff;
}

.recent-prompts-table .ant-table-tbody {
  background-color: MainStyle.$theme-background-third;
}

.recent-prompts-table
  .ant-pagination
  .ant-pagination-prev
  .ant-pagination-item-link {
  color: #fff;
}

.recent-prompts-table
  .ant-pagination
  .ant-pagination-next
  .ant-pagination-item-link {
  color: #fff;
}

.recent-prompts-table .ant-pagination .ant-pagination-item a {
  color: #fff !important;
  background: transparent !important;
}

.recent-prompts-table .ant-pagination .ant-pagination-item-active a {
  color: MainStyle.$theme-btn !important;
  background: transparent !important;
}

.recent-prompts-table .ant-pagination .ant-pagination-item-active {
  color: MainStyle.$theme-btn !important;
  border-color: MainStyle.$theme-btn !important;
  background: transparent !important;
}

.mobile-menu {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 3rem;
  border: 2px solid #fff;
  border-radius: 6px;
  padding: 7px;
}

.bar {
  width: 30px;
  height: 4px;
  background-color: #fff;
  margin: 4px 0;
  transition: 0.4s;
}

// .css-1wvake5 {
//   min-width: 0rem !important;
// }

/* Animate the menu button into an "X" on click */
.mobile-menu.open .bar:nth-child(1) {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.mobile-menu.open .bar:nth-child(2) {
  opacity: 0;
}

.mobile-menu.open .bar:nth-child(3) {
  transform: rotate(45deg) translate(-5px, -6px);
}

.library-btn-position {
  position: absolute;
  bottom: 5%;
  left: 33%;
}

.hidden-menu-item {
  display: none;
}

@media only screen and (max-width: 2560px) and (min-width: 2305px) {
  .costimize-sidebar {
    // width: 26rem !important;
    background: MainStyle.$theme-background-third;
    height: 100vh !important;
    position: fixed !important;
    border: none !important;
  }

  .filter-avatar-img {
    position: absolute;
    left: 44% !important;
    top: 0%;
  }

  .library-btn-position {
    left: 44%;
  }

  .css-1wvake5 {
    // width:100% !important;
    max-width: 13.3rem !important;
  }
  .sidebar-text-mobile {
    display: block !important;
  }
}

@media only screen and (max-width: 2304px) and (min-width: 2161px) {
  .costimize-sidebar {
    // width: 26rem !important;
    background: MainStyle.$theme-background-third;
    height: 100vh !important;
    position: fixed !important;
    border: none !important;
  }

  .filter-avatar-img {
    position: absolute;
    left: 43% !important;
    top: 0%;
  }

  .library-btn-position {
    left: 43%;
  }

  .css-1wvake5 {
    // width:100% !important;
    max-width: 12rem !important;
  }
  .sidebar-text-mobile {
    display: block !important;
  }
}

@media only screen and (max-width: 2160px) and (min-width: 1922px) {
  .costimize-sidebar {
    // width: 24rem !important;
    background: MainStyle.$theme-background-third;
    height: 100vh !important;
    position: fixed !important;
    border: none !important;
  }

  .filter-avatar-img {
    position: absolute;
    left: 43% !important;
    top: 0%;
  }

  .library-btn-position {
    left: 43%;
  }
  .sidebar-text-mobile {
    display: block !important;
  }
  .css-1wvake5 {
    // width:100% !important;
    max-width: 11.5rem !important;
  }
}

@media only screen and (max-width: 1921px) and (min-width: 1681px) {
  .costimize-sidebar {
    // width: 20rem !important;
    background: MainStyle.$theme-background-third;
    height: 100vh !important;
    position: fixed !important;
    border: none !important;
  }

  .filter-avatar-img {
    position: absolute;
    left: 42% !important;
    top: 0%;
  }

  .css-1wvake5 {
    // width:100% !important;
    max-width: 10.2rem !important;
  }

  .library-btn-position {
    left: 42% !important;
  }
  .sidebar-text-mobile {
    display: block !important;
  }
}

@media only screen and (max-width: 1680px) and (min-width: 1601px) {
  .costimize-sidebar {
    // width: 18rem !important;
    background: MainStyle.$theme-background-third;
    height: 100vh !important;
    position: fixed !important;
    border: none !important;
  }

  .filter-avatar-img {
    position: absolute;
    left: 41% !important;
    top: 0%;
  }

  .library-btn-position {
    left: 41% !important;
  }
  .sidebar-text-mobile {
    display: block !important;
  }
  .css-1wvake5 {
    // width:100% !important;
    max-width: 8.5rem !important;
  }
}

@media only screen and (max-width: 1600px) and (min-width: 1471px) {
  .costimize-sidebar {
    background: MainStyle.$theme-background-third;
    // max-width: 3rem !important;
    // width: 18rem !important;
    height: 100vh !important;
    position: fixed !important;
    border: none !important;
  }

  .filter-avatar-img {
    position: absolute;
    left: 41% !important;
    top: 0%;
  }

  .library-btn-position {
    left: 41% !important;
  }
  .sidebar-text-mobile {
    display: block !important;
  }
  .css-1wvake5 {
    // width:100% !important;
    max-width: 8.2rem !important;
  }
}

@media only screen and (max-width: 1470px) and (min-width: 1441px) {
  .costimize-sidebar {
    // width: 17rem !important;
    background: MainStyle.$theme-background-third;
    height: 100vh !important;
    position: fixed !important;
    border: none !important;
  }

  .filter-avatar-img {
    position: absolute;
    left: 40% !important;
    top: 0%;
  }

  .library-btn-position {
    left: 40% !important;
  }

  .css-1wvake5 {
    // width:100% !important;
    max-width: 8rem !important;
  }
  .sidebar-text-mobile {
    display: block !important;
  }
}

@media only screen and (max-width: 1440px) and (min-width: 1367px) {
  .filter-avatar-img {
    position: absolute;
    left: 40% !important;
    top: 0%;
  }

  .library-btn-position {
    left: 41% !important;
  }

  .css-1wvake5 {
    // width:100% !important;
    max-width: 8rem !important;
  }
}

@media only screen and (max-width: 1366px) and (min-width: 1281px) {
  .filter-avatar-img {
    position: absolute;
    left: 40% !important;
    top: 0%;
  }

  .library-btn-position {
    left: 40% !important;
  }

  .css-1wvake5 {
    // width:100% !important;
    max-width: 8rem !important;
  }
  .sidebar-text-mobile {
    display: block !important;
  }
}

@media only screen and (max-width: 1280px) and (min-width: 1081px) {
  .sidebar-text-mobile {
    display: block !important;
  }
  .filter-avatar-img {
    position: absolute;
    left: 38% !important;
    top: 0%;
  }

  .library-btn-position {
    left: 38% !important;
  }

  .css-1wvake5 {
    // width:100% !important;
    max-width: 8rem !important;
  }
}

@media only screen and (max-width: 1080px) and (min-width: 1025px) {
  .costimize-sidebar {
    // min-width: 14rem !important;
    // width: 14rem !important;
    background: MainStyle.$theme-background-third;
    height: 100vh !important;
    position: fixed !important;
    border: none !important;
  }

  .filter-avatar-img {
    position: absolute;
    left: 35% !important;
    top: 0%;
  }

  .library-btn-position {
    left: 35% !important;
  }

  .label-sidemenu {
    font-size: 8px !important;
    text-align: center;
    color: #fff;
    // font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    // text-align: left;
  }

  .css-1wvake5 {
    // width:100% !important;
    max-width: 7rem !important;
  }
  .sidebar-text-mobile {
    display: block !important;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 769px) {
  .costimize-sidebar {
    // min-width: 14rem !important;
    // width: 14rem !important;
    background: MainStyle.$theme-background-third;
    height: 100vh !important;
    position: fixed !important;
    border: none !important;
  }

  .filter-avatar-img {
    position: absolute;
    left: 35% !important;
    top: 0%;
  }

  .library-btn-position {
    left: 35% !important;
  }

  .label-sidemenu {
    font-size: 7px !important;
    text-align: center;
    color: #fff;
    // font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    // text-align: left;
  }

  .lable-sidemenu-icon {
    width: 33px;
  }

  .css-1wvake5 {
    // width:100% !important;
    max-width: 6rem !important;
  }

  .navbarNew1 a.active,
  .DashboardNavicon {
    font-size: 25px;
    // margin-right: 0.8rem;
    margin-top: 0px;
  }
  .sidebar-text-mobile {
    display: block !important;
  }
}

@media only screen and (max-width: 768px) and (min-width: 427px) {
  .sidebar-mobile {
    display: block;
  }

  // .sidebar-desktop {
  //   display: none;
  // }

  .filter-avatar-img {
    position: absolute;
    left: 34% !important;
    top: 0%;
  }

  .filterResultCard-bg {
    background: MainStyle.$theme-background-secondary;
    border-radius: 18px;
    margin-top: 3rem;
    height: 17rem !important;
  }

  .body-margin-top {
    margin-top: 4rem;
    // padding: 0 2rem;
    margin-bottom: 4.5rem;
    /* margin-left: 2rem; */
  }

  .body-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .body-margin-top-mobile {
    margin-top: 0 !important;
  }

  .sidebar-footer-body {
    width: 27% !important;
  }

  .sidebar-footer {
    width: 100% !important;
  }

  .costimize-sidebar > .ps-sidebar-container {
    // background-image: linear-gradient(
    //   150deg,
    //   #2c0e34 5%,
    //   MainStyle.$theme-background-primary 44%
    // ) !important;
  }

  .library-btn-position {
    left: 34% !important;
  }
  .sidebar-text-mobile {
    display: none !important;
  }
  .navbarNew1 a.active,
  .DashboardNavicon {
    font-size: 20px;
    margin-top: 0px;
  }
  .DashboardNavicon {
    font-size: 20px;
    margin-top: 0px;
  }
}

@media only screen and (max-width: 426px) and (min-width: 377px) {
  .sidebar-mobile {
    display: block;
  }

  .sidebar-mobile {
    z-index: 2;
    display: block;
    position: absolute;
    top: -44px;
  }

  // .sidebar-desktop {
  //   display: none;
  // }

  .filter-avatar-img {
    position: absolute;
    left: 35% !important;
    top: 0%;
  }

  .body-margin-top {
    margin-top: 4.5rem;
    // padding: 0 2rem;
    margin-bottom: 4.5rem;
    /* margin-left: 2rem; */
  }

  .body-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .body-margin-top-mobile {
    margin-top: 0 !important;
  }

  .sidebar-footer-body {
    width: 49% !important;
  }

  .sidebar-footer {
    width: 100% !important;
  }

  .library-btn-position {
    left: 35% !important;
  }
  .sidebar-text-mobile {
    display: none !important;
  }
  .navbarNew1 a.active,
  .DashboardNavicon {
    font-size: 15px;
    margin-top: 0px;
  }
  .DashboardNavicon {
    font-size: 15px;
    margin-top: 0px;
  }
}

@media only screen and (max-width: 768px) and (min-width: 427px) {
  .sidebar-mobile {
    z-index: 2;
    display: block;
    position: absolute;
    top: -44px;
  }
  .sidebar-text-mobile {
    display: none !important;
  }
  .navbarNew1 a.active,
  .DashboardNavicon {
    font-size: 18px;
    margin-top: 0px;
  }
  .DashboardNavicon {
    font-size: 18px;
    margin-top: 0px;
  }
}

@media only screen and (max-width: 376px) and (min-width: 321px) {
  // .sidebar-mobile {
  //   display: block;
  // }
  .sidebar-mobile {
    z-index: 2;
    display: block;
    position: absolute;
    top: -44px;
  }

  .navbar-brand {
    padding-left: 0%;
  }

  // .sidebar-desktop {
  //   display: none;
  // }

  .filter-avatar-img {
    position: absolute;
    left: 33% !important;
    top: 0%;
  }

  .body-margin-top {
    margin-top: 4.5rem;
    // padding: 0 2rem;
    margin-bottom: 4.5rem;
    /* margin-left: 2rem; */
  }

  .body-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .body-margin-top-mobile {
    margin-top: 0 !important;
  }

  .sidebar-footer-body {
    width: 49% !important;
  }

  .sidebar-footer {
    width: 100% !important;
  }

  .costimize-sidebar > .ps-sidebar-container {
    // background-image: linear-gradient(
    //   150deg,
    //   #2c0e34 5%,
    //   MainStyle.$theme-background-primary 44%
    // ) !important;
  }

  .library-btn-position {
    left: 33% !important;
  }
  .sidebar-text-mobile {
    display: none !important;
  }
  .navbarNew1 a.active,
  .DashboardNavicon {
    font-size: 14px;
    margin-top: 0px;
  }
  .DashboardNavicon {
    font-size: 14px;
    margin-top: 0px;
  }
}

@media (max-width: 320px) {
  .sidebar-mobile {
    z-index: 2;
    display: block;
    position: absolute;
    top: -44px;
  }
  .sidebar-text-mobile {
    display: none !important;
  }
  .navbar-brand {
    padding-left: 1%;
  }

  .filterResultCard-bg {
    background: MainStyle.$theme-background-secondary;
    border-radius: 18px;
    margin-top: 3rem;
    height: 17rem !important;
  }

  .sidebar-mobile {
    display: block;
  }

  // .sidebar-desktop {
  //   display: none;
  // }

  .filter-avatar-img {
    position: absolute;
    left: 30% !important;
    top: 0%;
  }

  .body-margin-top {
    margin-top: 4.5rem;
    // padding: 0 2rem;
    margin-bottom: 4.5rem;
    /* margin-left: 2rem; */
  }

  .body-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }

  .body-margin-top-mobile {
    margin-top: 0 !important;
  }

  .sidebar-footer-body {
    width: 64% !important;
  }

  .sidebar-footer {
    width: 100% !important;
  }

  .library-btn-position {
    left: 30% !important;
  }
  .navbarNew1 a.active,
  .DashboardNavicon {
    font-size: 22px;
    margin-top: 0px;
  }
  .DashboardNavicon {
    font-size: 12px;
    margin-top: 0px;
  }
}
