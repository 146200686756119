@use "../../MainStyle";
.modal-body-main {
  // background-color: #1E1E1E;
}
.modal-body {
  margin: 2rem;
  // background: MainStyle.$theme-background-secondary;
  // background-color: #D6D3D3;
  min-height: 90vh;
  // width: 100%;
  background-color: #191D23;
  color:#fff;
  border-radius: 10px;
  padding: 1rem;

}
.token-model-title{
  color:#E3E3E3;
}
.modal-input-bg {
  // background: MainStyle.$theme-background-third;
  // background-color: #696969;
  // border: 1px solid #696969;
  background-color: #262C36;
  border: 1px solid #262C36;
  
  border-radius: none;
  color: #E3E3E3;
}
.modal-input-icon-bg{
  background-color: #E11958;
  border: 1px solid #E11958;
  color: #E3E3E3;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 5px;
}
.modal-question {
  padding: 0.5rem;
  // background: MainStyle.$theme-background-third;
  // background-color: #808080;
  background-color: #E11958;
  // border: 1px solid #48495e ;
  border: none;
  border-radius: 5px;
  color: #E3E3E3;
  text-align: end;
}
.modal-question-box {
  margin: 1rem;
  display: flex;
  justify-content: right;
  padding: 1rem;
  margin-left: 50%;
  // max-width: 200px;
}
.modal-answer {
  // background: MainStyle.$theme-background-third;
  // background-color: #979797;
  background-color:#262C36;
  // border: 1px solid #48495e ;
  border: none;
  padding: 0.5rem;
  border-radius: 5px;
  color: #fff;
  text-align: start;
  width: 70%;
}
.modal-answer-box {
  margin: 1rem;
  display: flex;
  justify-content: left;
  padding: 1rem;
  margin-right: 50%;
  // max-width: 200px;
}
.modal-input-field {
  padding: 1rem 1.3rem;
}
.modal-icon {
  font-size: 20px !important;
  // padding-bottom: 2px;
}
.ant-spin .ant-spin-dot-item {
  background-color: #E11958;
}
.navlogo-model{
  padding: 0.3rem;
}
.modal-text-paragraph-title{
font-weight: 600;
}
.modal-text-paragraph{
  padding-bottom: 0.5rem;
}
.modal-spinner {
  width: 300px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.demo-model-table {
  width: 100%;
  border: 1px solid #262C36;
  border: none;
  padding: 1rem;
  display: block;
  overflow-x: auto;
}
.demo-model-table thead tr th {
  border: 1px solid #262C36;
  text-align: center;
  border: none;
}
.demo-model-table tbody tr td {
  border: 1px solid #262C36;
  text-align: center;
  border: none;
  padding-top: 1rem;
}
.demo-model-select {
  width: 100%;
  // border: 1px solid #696969;
  border: 1px solid #262C36;
  border-radius: 7px;
}
.demo-model-select .ant-select-selector {  
  border: 1px solid #262C36 !important;
}
.demo-model-select .ant-select-selection-item {
  // color: #696969;
  color: #fff;
}
.demo-model-select.ant-select .ant-select-arrow{
  color: #fff;
}
.model-output-tag{
  border: 1px solid #FDAD09 !important;
}
.model-input-tag{
  border: 1px solid #FF3E3E !important;
}
.model-table-border{
  border-right: 2px solid #262C36 !important;
  border-left: none !important;
  width: 20%;
}
.model-table-border-leftside{
  border-left: none !important;
  width: 20%;
}